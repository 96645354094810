import { TextField } from '@mui/material';

const OneLineInput = ({
  inputLabel,
  label,
  type,
  name,
  onChange,
  onBlur,
  error,
  helperText,
}) => {
  return (
    <>
      <p
        style={{
          margin: '0 0 0 0',
          fontSize: '1.1rem',
          color: 'rgba(83 , 83, 83, 0.9)',
          maxWidth: '750px',
        }}
      >
        {inputLabel}
      </p>
      <TextField
        fullWidth
        variant='filled'
        label={label}
        type={type}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        error={error}
        helperText={helperText}
        sx={{mt: 1, mb: 1}}
      />
    </>    
  );
}

export default OneLineInput;