import { supabase } from '../../../Utilities/SupabaseClient';
import { v4 as uuidv4 } from 'uuid';

// Adds the suppler information to the suppliers table in the DB.
const addInventoryToDB = async (
	uid,
	fuel,
	scope,
	values,
	units,
	startDate,
	endDate,
	tableName,
	notes
) => {
	let result;
	const uuid = uuidv4();
	try {
		// Using code form from Supabase docs.
		// https://supabase.com/docs/guides/database/tables
		result = await supabase.from('emissions_inventory').insert([
			{
				record_creator_user_id: uid,
				item_id: uuid,
				name: fuel,
				scope: scope,
				amount: values.fuelUsed,
				units: units,
				start_date: startDate,
				end_date: endDate,
				factor_source_table: tableName,
				notes: notes,
			},
		]);
	} catch (e) {
		console.log(e);
		throw e;
	}
	// Second error block here in case the catch block does not
	// recognize the error. Not sure how Supabase handles the error
	// message so including this here. May not be necessary.
	if (result && result.error) {
		throw result.error;
	}
};

export default addInventoryToDB;
