import { MenuItem } from 'react-pro-sidebar';
import { Typography } from '@mui/material';

const LogOutButton = ({ title, icon, colors, onClick}) => {
  return (
    <MenuItem
      style={{
        color: colors.grey[100],
      }}
      onClick={onClick}
      icon={icon}
    >
      <Typography>{title}</Typography>
    </MenuItem>
  );
};

export default LogOutButton;
