import { supabase } from '../../../Utilities/SupabaseClient';
const Airtable = require('airtable');

// This function gets the emissions inventory from the DB. It should only get
// suppliers associated with the logged in user, given the RLS setup.
const getInventoryFromDB = async () => {
  try {
    const session = await supabase.auth.getSession();
    const userid = session.data.session.user.id;
    const {data, err} = await supabase.from('emissions_inventory').select('*').eq('record_creator_user_id', userid).order('created_at', {ascending: false});
    // Error block here in case the catch block does not
    // recognize the error. Not sure how Supabase handles the error
    // message so including this here. May not be necessary.
    if (err) throw (err);
    const processedData = processData(data);
    return processedData;
  } catch(e) {
    console.log(e);
    throw(e);
  }
}

// This function takes the data from the DB and returns the data needed by
// the MUI grid table used to display the emissions inventory.
const processData = async (data) => {
  const processedData = [];
  for (let j = 0; j < data.length; j++) {
    const item = data[j];
    // In the case of bad data, do not include in emissions inventory.
    if (!item['name'] || !item['units']) {
      continue;
    }
    let calculatedco2e = 0;
    const emissionsFactors = await getEmissionsFactors(item);
    if (emissionsFactors['Name'] !== 'No record found') {
      // Using EPA Global Warming Potentials
      // Units below are metric tons
      calculatedco2e = (1 / 1000) * item['amount'] * (emissionsFactors['CO2 Factor'] + (emissionsFactors['CH4 Factor'] * 25 / 1000) + (emissionsFactors['NO2 Factor'] * 298 / 1000));
    }
    const itemToAdd = {
      ...item,
      co2e: calculatedco2e,
    }
    processedData.push(itemToAdd);
  }
  return processedData;
}

// Get emissions factors from Airtable.
const getEmissionsFactors = async (item) => {
  const base = new Airtable({apiKey: process.env.REACT_APP_AIRTABLE_API}).base(process.env.REACT_APP_AIRTABLE_BASE);
  const getRecordsPromise =  new Promise((resolve, reject) => {
    base(item['factor_source_table']).select({
      maxRecords: 100,
      view: 'Grid view',
      filterByFormula: `{Name} = "${item['name']}"`      
    }).firstPage((err, records) => {
      if (err) return reject ([]);
      return resolve(records);       
    })
  });

  let returnedRecord = [];
  try {
    returnedRecord = await getRecordsPromise;
  } catch (error) {
    console.log(error);
    return ({Name: 'No record found'});
  }
  return returnedRecord[0]['fields'];

} 

export default getInventoryFromDB;