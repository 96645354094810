import { Routes, Route, BrowserRouter } from 'react-router-dom';
// import Dashboard from '../Dashboard/Dashboard';
import { ColorModeContext, useMode } from '../../theme';
import { CssBaseline, ThemeProvider } from '@mui/material';
import BarWrapper from '../BarWrapper/BarWrapper';
// import AddSupplier from '../AddSupplier/AddSupplier';
// import SuppliersList from '../SuppliersList/SuppliersList';
// import SupplierPage from '../SupplierPage/SupplierPage';
// import AssessmentSelector from '../AsssessmentSelector/AssessmentSelector';
// import ResponseList from '../ResponseList/ResponseList';
// import ResponseSelector from '../ResponseSelector/ResponseSelector';

import { AuthProvider } from '../../contexts/AuthContext';
import ProtectedRoute from '../../Components/ProtectedRoute/ProtectedRoute';
import NewPassword from '../NewPassword/NewPassword';
// import SendAssessment from '../SendAssessment/SendAssessment';
// import EmissionsDetail from '../EmissionsDetail/EmissionsDetail';

// Once auth this done, this component is shown. It has the front end routing
// structure.
const AppMain = ({ session }) => {
  const [theme, colorMode] = useMode();
  return (
    <ColorModeContext.Provider value={colorMode}>
      <ThemeProvider theme={theme}>
        <CssBaseline />
        <AuthProvider>
          <BrowserRouter>
            <Routes>
              <Route
                path='/newpassword'
                element={
                  <ProtectedRoute>
                    <NewPassword />
                  </ProtectedRoute>
                }
              />
              <Route
                path='/*'
                element={
                  <ProtectedRoute>
                    <BarWrapper />
                  </ProtectedRoute>
                }
              />
            </Routes>
          </BrowserRouter>
        </AuthProvider>
      </ThemeProvider>
    </ColorModeContext.Provider>
  );
};

export default AppMain;
