import { supabase } from '../../../Utilities/SupabaseClient';

// This function gets the emissions inventory from the DB. It should only get
// suppliers associated with the logged in user, given the RLS setup.
const deleteInventoryItem = async (itemid) => {
  try {
    const {err} = await supabase.from('emissions_inventory').delete().match({'item_id': itemid });
    // Error block here in case the catch block does not
    // recognize the error. Not sure how Supabase handles the error
    // message so including this here. May not be necessary.
    if (err) throw (err);
  } catch(e) {
    console.log(e);
    throw(e);
  }
}

export default deleteInventoryItem;