import { useState } from 'react';
import { Box, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { DataGrid, GridToolbar } from '@mui/x-data-grid';
import Header from '../../Components/Headers/Header';
import { useEffect } from 'react';
import styles from './EmissionsInventory.module.css';
import getInventoryFromDB from './Utilities/getInventoryFromDB';
import getInventoryColumns from './Utilities/getInventoryColumns';

// Shows a MUI Grid with a supplier's complete emissions 
// inventory.
const EmissionsInventory = () => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);
  const [inventoryData, setInventoryData] = useState([]);

  const columns = getInventoryColumns(colors, setInventoryData);

  // Bring in the emissions inventory from the DB.
  useEffect(() => {
    async function getData() {
      const incomingInventoryData = await getInventoryFromDB();
      setInventoryData(incomingInventoryData);
    }
    getData();
  }, []);
  
  return (
    <Box m='20px'>
      <Header
        title='Emissions Inventory'
        subtitle="Listing of Your Organization's Emissions"
      />
      <div className={styles.supplierslisttablewrapper}>
        <Box
          m='40px 0 0 0'
          height='75vh'
          sx={{
            '& .MuiDataGrid-root': {
              border: 'none',
            },
            '& .MuiDataGrid-cell': {
              borderBottom: 'none',
            },
            '& .name-column--cell': {
              color: colors.greenAccent[300],
            },
            '& .MuiDataGrid-columnHeaders': {
              backgroundColor: colors.blueAccent[700],
              borderBottom: 'none',
            },
            '& .MuiDataGrid-virtualScroller': {
              backgroundColor: colors.primary[400],
            },
            '& .MuiDataGrid-footerContainer': {
              borderTop: 'none',
              backgroundColor: colors.blueAccent[700],
            },
            '& .MuiCheckbox-root': {
              color: `${colors.greenAccent[200]} !important`,
            },
            '& .MuiDataGrid-toolbarContainer .MuiButton-text': {
              color: `${colors.grey[100]} !important`,
            },
          }}
        >
          <DataGrid
            rows={inventoryData}
            columns={columns}
            components={{ Toolbar: GridToolbar }}
            sx={{
              fontSize: '14px',
            }}
          />
        </Box>
      </div>
    </Box>
  );

}

export default EmissionsInventory;