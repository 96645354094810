import { Routes, Route } from 'react-router';
import SideBarPanel from '../../NavBars/SideBarPanel';
import TopBar from '../../NavBars/TopBar';
import ProtectedRoute from '../../Components/ProtectedRoute/ProtectedRoute';
import Dashboard from '../Dashboard/Dashboard';
import NewPassword from '../NewPassword/NewPassword';
import InventoryEntry from '../InventoryEntry/InventoryEntry';
import styles from './BarWrapper.module.css';
import EmissionsInventory from '../EmissionsInventory/EmissionsInventory';

// This component house the TopBar and Sidebar for
// pages that use those. It serves as a wrapper.
const BarWrapper = () => {
  return (
    <>
      <SideBarPanel />
      <main className={styles.barwrappermain}>
        <nav>
          <TopBar />
        </nav>
        <Routes>
          <Route
            path='/'
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path='/dashboard'
            element={
              <ProtectedRoute>
                <Dashboard />
              </ProtectedRoute>
            }
          />
          <Route
            path='/bars/oilgas'
            element={
              <ProtectedRoute>
                <InventoryEntry 
                  title='Petroleum'
                  subtitle='Emissions from burning of petroleum products'
                  scope={1}
                  explainer='petroleum products'
                  tableName='Oil / Nat Gas'
                />
              </ProtectedRoute>
            }
          />
          <Route
            path='/bars/coal'
            element={
              <ProtectedRoute>
                <InventoryEntry 
                  title='Coal'
                  subtitle='Emissions from burning of coal'
                  scope={1}
                  explainer='coal'
                  tableName='Coal'
                />
              </ProtectedRoute>
            }
          />
          <Route
            path='/bars/wood'
            element={
              <ProtectedRoute>
                <InventoryEntry 
                  title='Wood'
                  subtitle='Emissions from burning of wood'
                  scope={1}
                  explainer='wood'
                  tableName='Wood'
                />
              </ProtectedRoute>
            }
          />
          <Route
            path='/bars/otherfuel'
            element={
              <ProtectedRoute>
                <InventoryEntry 
                  title='Other fuel types'
                  subtitle='Emissions from burning of other fuels'
                  scope={1}
                  explainer='less common fuel types'
                  tableName='Other Fuel'
                />
              </ProtectedRoute>
            }
          />
          <Route
            path='/bars/transport'
            element={
              <ProtectedRoute>
                <InventoryEntry 
                  title='Vehicles and Aviation'
                  subtitle='Emissions from vehicles and planes'
                  scope={1}
                  explainer='transportation'
                  tableName='Transport'
                />
              </ProtectedRoute>
            }
          />
          <Route
            path='/bars/electricity'
            element={
              <ProtectedRoute>
                <InventoryEntry 
                  title='Electricity'
                  subtitle='Emissions from purchased electricity'
                  scope={2}
                  explainer='purchased electricity'
                  tableName='Electricity'
                />
              </ProtectedRoute>
            }
          />
          <Route
            path='/bars/steamheat'
            element={
              <ProtectedRoute>
                <InventoryEntry 
                  title='Steam & Heat'
                  subtitle='Emissions from purchased steam and heat'
                  scope={2}
                  explainer='purchased steam and heat'
                  tableName='Steam / heat'
                />
              </ProtectedRoute>
            }
          />
          <Route
            path='/bars/emissionsinventory'
            element={
              <ProtectedRoute>
                <EmissionsInventory />
              </ProtectedRoute>
            }
          />
        </Routes>
      </main>
    </>
  );
}

export default BarWrapper;