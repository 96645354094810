import { useAuth } from '../../contexts/AuthContext';
import LoginWidget from '../../Components/LoginWidget/LoginWidget';
import Feb2023LoginWidget from '../LoginWidget/Feb2023LoginWidget';

// ProtectedRoute to wrap around routes that will be protected
const ProtectedRoute = ({ children }) => {
  // consume AuthContext.js
  const auth = useAuth();

  // if no session found show loginWidget otherwise the children of ProtectedRoute
  return !auth.session ? <Feb2023LoginWidget /> : children;
};

export default ProtectedRoute;
