import { DarkModeOutlined, LightModeOutlined } from '@mui/icons-material';
import { Box, IconButton, useTheme } from '@mui/material';
import { useContext } from 'react';
import { ColorModeContext } from '../theme';

// This component renders the top bar at the top of the Dashboard.
const TopBar = () => {
  const theme = useTheme();
  const colorMode = useContext(ColorModeContext);
  return(
    <Box display="flex" justifyContent="flex-end" width="100%" p={1} pr={2}>
      {/* Icons */}
      <Box display="flex">
        <IconButton onClick={colorMode.toggleColorMode}>
          {theme.palette.mode === 'light' ?
            <LightModeOutlined sx={{ fontSize: 22 }}/> : <DarkModeOutlined sx={{ fontSize: 22 }}/>
          }
        </IconButton>
        {/* <IconButton>
          <NotificationsOutlined sx={{ fontSize: 22 }}/>
        </IconButton>
        <IconButton>
          <SettingsOutlined sx={{ fontSize: 22 }}/>
        </IconButton>
        <IconButton>
          <PersonOutlined sx={{ fontSize: 22 }}/>
        </IconButton> */}
      </Box>
    </Box>
  );

}

export default TopBar;