import { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, Typography, useTheme } from '@mui/material';
import { tokens } from '../../theme';
import { supabase } from '../../Utilities/SupabaseClient';
import Header from '../../Components/Headers/Header';
import FactCheckOutlinedIcon from '@mui/icons-material/FactCheckOutlined';
import AddchartOutlinedIcon from '@mui/icons-material/AddchartOutlined';
import CloudCircleIcon from '@mui/icons-material/CloudCircle';
import StatBox from '../../Components/ChartComponents/StatBox';
import BarChart from '../../Components/ChartComponents/BarChart';
import getInventoryFromDB from '../EmissionsInventory/Utilities/getInventoryFromDB';

// This is the component that renders the Dashboard for the app.
// There is a grid with 2 rows. The top row of the grid has 4
// cards. The second row has a bar chart (emissions chart)
// and a box with recent assessment submissions.
const Dashboard = () => {
  const navigate = useNavigate();
  const [itemsArr, setItemsArr] = useState([]);
  const [emissions, setEmissions] = useState('');
  const [inventorySize, setInventorySize] = useState('');
  const [scope1Entry, setScope1Entry] = useState(false);
  const [scope2Entry, setScope2Entry] = useState(false);
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  useEffect(() => {
    supabase.auth.onAuthStateChange((_event) => {
      if (_event === 'PASSWORD_RECOVERY') {
        console.log('Use navigate call');
        navigate(process.env.REACT_APP_PASSWORD_RESET_URL);
      }
    });
  }, [navigate]);

  useEffect(() => {
    const getData = async () => {
      const incomingInventoryData = await getInventoryFromDB();
      let emissionsTotal = 0;
      const session = await supabase.auth.getSession();
      const userid = session.data.session.user.id;  
      for (let k = 0; k < incomingInventoryData.length; k++) {
        if (userid === incomingInventoryData[k]['record_creator_user_id']) emissionsTotal += incomingInventoryData[k]['co2e'];
      };
      setEmissions(emissionsTotal.toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ','));
      setItemsArr(incomingInventoryData);
      setInventorySize(incomingInventoryData.length.toString());
      // Dealing with scope 1 and scope 2 cards. Bools refer
      // to whether any scope 1 or scope 2 entries exist.
      for (let i = 0; i < incomingInventoryData.length; i++) {
        if (incomingInventoryData[i]['scope'] === 1) {
          setScope1Entry(true);
          break;
        }
      }
      for (let j = 0; j < incomingInventoryData.length; j++) {
        if (incomingInventoryData[j]['scope'] === 2) {
          setScope2Entry(true);
          break;
        }
      }
    }
    getData();
  }, []);

  return (
    <>
      <Box m='20px'>
        <Box>
          <Header title='DASHBOARD' subtitle='Welcome to your dashboard' />
        </Box>

        {/* GRID & CHARTS */}
        <Box
          display='grid'
          gridTemplateColumns='repeat(12, 1fr)'
          gridAutoRows='140px'
          gap='20px'
        >
          {/* ROW 1 */}
          <Box
            gridColumn='span 3'
            backgroundColor={colors.primary[400]}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <StatBox
              title={inventorySize}
              subtitle="Inventory Items"
              show={false}
              // progress="0.10"
              // progresspct="10%"
              icon={
                <FactCheckOutlinedIcon
                  sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
                />
              }
            />
          </Box>
          <Box
            gridColumn='span 3'
            backgroundColor={colors.primary[400]}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <StatBox
              title={scope1Entry ? 'Entered' : 'Not Entered'} 
              subtitle='Scope 1'
              show={true}
              progress='1.0'
              progresspct='100%'
              icon={
                <AddchartOutlinedIcon
                  sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
                />
              }
            />
          </Box>
          <Box
            gridColumn='span 3'
            backgroundColor={colors.primary[400]}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <StatBox
              title={scope2Entry ? 'Entered' : 'Not Entered'}
              show={true}
              subtitle='Scope 2'
              progress='1.0'
              progresspct='100%'
              icon={
                <AddchartOutlinedIcon
                  sx={{ color: colors.greenAccent[600], fontSize: '26px' }}
                />
              }
            />
          </Box>
          <Box
            gridColumn='span 3'
            backgroundColor={colors.primary[400]}
            display='flex'
            alignItems='center'
            justifyContent='center'
          >
            <StatBox
              title={emissions}
              subtitle='Emissions (Metric Tons)'
              // show={false}
              progress='1'
              increase=''
              icon={
                <CloudCircleIcon
                  sx={{ color: colors.greenAccent[600], fontSize: '36px' }}
                />
              }
            />
          </Box>

          {/* ROW 2 */}
          <Box
            gridColumn='span 8'
            gridRow='span 3'
            backgroundColor={colors.primary[400]}
          >
            <Typography
              variant='h5'
              fontWeight='600'
              sx={{ padding: '30px 30px 0 30px' }}
            >
              Largest Emissions Sources
            </Typography>
            <Box height='400px' mt='-20px'>
              <BarChart isDashboard={true} inventory={itemsArr} />
            </Box>
          </Box>
          <Box
            gridColumn='span 4'
            gridRow='span 3'
            backgroundColor={colors.primary[400]}
            overflow='auto'
          >
            <Box
              display='flex'
              justifyContent='space-between'
              alignItems='center'
              borderBottom={`2px solid ${colors.grey[700]}`}
              colors={colors.grey[100]}
              p='15px'
            >
              <Typography
                color={colors.grey[100]}
                variant='h5'
                fontWeight='600'
              >
                Recent Emissions Sources
              </Typography>
            </Box>
            {itemsArr.slice(0, 4).map((item, index) => {
              return (
                <Box
                  key={index}
                  display='flex'
                  justifyContent='space-between'
                  alignItems='center'
                  borderBottom={`2px solid ${colors.grey[700]}`}
                  p='15px'
                >
                  <Box>
                    <Typography
                      color={colors.greenAccent[500]}
                      variant='h5'
                      fontWeight='600'
                    >
                      {item['name']}
                    </Typography>
                    <Typography color={colors.grey[100]}>
                      {item['amount'].toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} {item['units']}
                    </Typography>
                  </Box>
                  <Box color={colors.grey[100]}>{item['co2e'].toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')} MT CO2e</Box>
                </Box>
              );
            })}
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default Dashboard;
