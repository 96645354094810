import { Box, Button } from '@mui/material';
import { Link } from 'react-router-dom';
import deleteInventoryItem from './deleteInventoryItem';
import getInventoryFromDB from './getInventoryFromDB';

// Returns the columns needed to populate the MUI Grid.
const getInventoryColumns = (colors, setInventoryData) => {
  const columns = [
    { 
      field: 'name', 
      headerName: 'Emission Item', 
      flex: 1 
    },
    { 
      field: 'scope', 
      headerName: 'Scope', 
      flex: 0.5 
    },
    {
      field: 'units',
      headerName: 'Units',
      flex: 0.5,
    },
    {
      field: 'amount',
      headerName: 'Amount',
      flex: 0.5,
      type: 'number',
      valueFormatter: emissionsNumberFormatter,
    },
    {
      field: 'start_date',
      headerName: 'Start Date',
      flex: 0.8,
    },
    {
      field: 'end_date',
      headerName: 'End Date',
      flex: 0.8,
    },
    {
      field: 'co2e',
      headerName: 'Metric Tons CO2e',
      flex: 0.7,
      valueFormatter: emissionsNumberFormatter,
    },
    {
      field: 'notes',
      headerName: 'Notes',
      flex: 2,
    },
    {
      field: 'item_id',
      headerName: 'Remove',
      flex: 0.8,
      // Renders a button within the MUI Grid.
      renderCell: ({ row: { item_id } }) => {
        const handleDelete = async () => {
          await deleteInventoryItem(item_id);
          const incomingInventoryData = await getInventoryFromDB();
          setInventoryData(incomingInventoryData); 
        }
        return (
            <Box
              width="100%"
              display="flex"
              justifyContent="center"
            >
              <Button
                component={Link}
                onClick={handleDelete}
                sx={{
                  backgroundColor: colors.greenAccent[600],
                  '&:hover': {
                    backgroundColor: colors.greenAccent[700],
                  },
                  textTransform: 'none',
                  minHeight: '0',
                  minWidth: '0',
                  padding: '4px',
                  paddingLeft: '12px',
                  paddingRight: '12px',
                  fontSize: '12px'
                }}
              >
                Delete
              </Button>
            </Box>
        );
      }
    }
  ];
  return columns;
}

export default getInventoryColumns;

// Formats numbers in the MUI Grid
const emissionsNumberFormatter = (params) => {
  if (params.value == null) {
    return '';
  }

  const valueFormatted = Number(params.value).toFixed(1).toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',');
  return valueFormatted;
}