const Airtable = require('airtable');

// Gets the fuel types from Airtable
const getFuelTypes = async (tableName) => {
  const base = new Airtable({apiKey: process.env.REACT_APP_AIRTABLE_API}).base(process.env.REACT_APP_AIRTABLE_BASE);
  const getRecordsPromise =  new Promise((resolve, reject) => {
    base(tableName).select({
      maxRecords: 100,
      view: 'Grid view'
    }).firstPage((err, records) => {
      if (err) return reject ([]);
      return resolve(records);       
    })
  });

  let returnedRecords = [];
  try {
    returnedRecords = await getRecordsPromise;
  } catch (error) {
    console.log(error);
    return ([]);
  }
  const cleanedRecords = returnedRecords.map((item) => item['fields']);

  return cleanedRecords;
}

export default getFuelTypes;