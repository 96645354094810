import React, { useEffect, useState } from 'react';
import { useAuth } from '../../contexts/AuthContext';
import styles from './Feb2023LoginWidget.module.css';

// This component displays the login screen, allowing the user
// to login by magic link. Once the link is sent, the text changes
// to ask the user to check their email box for the link.
const Feb2023LoginWidget = () => {
  const auth = useAuth();

  const [loaded, setLoaded] = useState(false);
  const [showMagicLinkMsg, setMagicLinkMsg] = useState(false);
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [email2, setEmail2] = useState('');
  const [badCredentials, setBadCredentials] = useState('');

  useEffect(() => {
    // Using setTimeout to deal with brief flash of login widget as
    // it takes some millsecs for the auth state to load.
    setTimeout(() => {
      setLoaded(true);
    }, 200);
  }, []);

  const handleLoginPassword = async (e) => {
    e.preventDefault();
    const approved = process.env.REACT_APP_APPROVED.split('?');
    if (!approved.includes(email)) return;
    try {
      await auth.loginPassword(email, password, setBadCredentials);
    } catch (error) {}
  };

  const handleLoginWithLink = async (e) => {
    e.preventDefault();
    try {
      setMagicLinkMsg(true); // This will show the user the message to check email inbox
      const approved = process.env.REACT_APP_APPROVED.split('?');
      if (!approved.includes(email2)) return;
      await auth.loginOTP(email2);
    } catch (error) {
      setMagicLinkMsg(false);
    }
  };

  return (
    <>
      {loaded && (
        <div className={styles.loginpagewrapper}>
          <h1 className={styles.earthscopetext}>EarthScope</h1>
          <div className={styles.loginwidgetcontainer}>
            <div>
              {/* The text shown depends on whether the magic link has already been sent. If not,
              the text asks for the user's email. If so, the text ask the user to check their email
              for the magic link.  */}
              {!showMagicLinkMsg && (
                <>
                  <p className={styles.signintext}>
                    Sign in with email and password
                  </p>
                  <p className={styles.newuser}>
                    New user?{' '}
                    <a href='mailto:support@earthscopeco.com'>Contact us</a> for
                    access
                  </p>
                </>
              )}
              {showMagicLinkMsg ? (
                <span className={styles.sendingmagiclinktext}>
                  Sending login link to your email (if it exists in our system)
                  ... Click on the link to log in.
                </span>
              ) : (
                <>
                  <form onSubmit={(e) => handleLoginPassword(e)}>
                    <label
                      htmlFor='email'
                      className={styles.loginemailfieldlabel}
                    >
                      Email address
                    </label>
                    <input
                      id='email'
                      className={styles.loginemailinputfield}
                      type='email'
                      value={email}
                      onChange={(e) => setEmail(e.target.value)}
                    />
                    <label
                      htmlFor='password'
                      className={styles.loginemailfieldlabel}
                    >
                      Password
                    </label>
                    <input
                      id='password'
                      className={styles.loginemailinputfield}
                      type='password'
                      value={password}
                      onChange={(e) => {
                        setPassword(e.target.value);
                        if (badCredentials) setBadCredentials('');
                      }}
                    />
                    <div className={styles.loginpagebadcredentials}>
                      {badCredentials}
                    </div>
                    <div className={styles.loginbuttonwrapper}>
                      <button
                        className={styles.loginsendmagiclinkbutton}
                        aria-live='polite'
                        disabled={showMagicLinkMsg}
                      >
                        Continue
                      </button>
                    </div>
                  </form>
                  <form onSubmit={(e) => handleLoginWithLink(e)}>
                    <div className={styles.signinspacer}></div>
                    <p className={styles.signintext}>
                      Or sign in with email link
                    </p>
                    <label
                      htmlFor='email2'
                      className={styles.loginemailfieldlabel}
                    >
                      Email address
                    </label>
                    <input
                      id='email2'
                      className={styles.loginemailinputfield}
                      type='email'
                      value={email2}
                      onChange={(e) => setEmail2(e.target.value)}
                    />
                    <div className={styles.loginbuttonwrapper}>
                      <button
                        className={styles.loginsendmagiclinkbutton}
                        aria-live='polite'
                        disabled={showMagicLinkMsg}
                      >
                        Continue
                      </button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default Feb2023LoginWidget;
