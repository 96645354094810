import { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../../Utilities/SupabaseClient';
import styles from './NewPassword.module.css';

// Allows the user to change their password.
const NewPassword = () => {
  const navigate = useNavigate();
  const [showResetMsg, setResetMsg] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordErr, setPasswordErr] = useState('');
 
  const handlePasswordReset = async (e) => {
    e.preventDefault();
    try {
      const approved = process.env.REACT_APP_APPROVED.split('?');
      const user = await supabase.auth.getUser();
      if (!approved.includes(user.data.user.email)) return;
      if (password.length < 6) { // Pwd must be 6 chars or more
        setPasswordErr('Password too short');
        return;
      }
      const { data, error } = await supabase.auth.updateUser({
        password: password,
      })
      if (error) throw error;
      setResetMsg(true); // This will show the user the message that the password has changed
      // In 8 secs, takes the user to the dashboard.
      setTimeout(()=> {
        navigate('/dashboard');
      }, 8000);
    } catch (err) {
      setResetMsg(false);
    }
  };
  return (
    <div className={styles.newpasswordwrapper}>
        <h1 className={styles.earthscopetext}>
          EarthScope
        </h1>
          <div className={styles.newpasswordcontainer}>
            <div>
              {/* The text shown depends on whether the magic link has already been sent. If not,
              the text asks for the user's email. If so, the text ask the user to check their email
              for the magic link.  */}
              {!showResetMsg && (
                <>
                  <p className={styles.newpasswordtext}>
                    Choose a new password
                  </p>
                </>
              )}
              {showResetMsg ? (
                <span className={styles.newpasswordresettext}>
                  Password reset. Sending you to the EarthScope app in 10 seconds.
                </span>
              ) : (
                <>
                  <form onSubmit={(e) => handlePasswordReset(e)}>
                    <label htmlFor='password' className={styles.loginemailfieldlabel}>
                      Password
                    </label>
                    <input
                      id='password'
                      className={styles.newpasswordinputfield}
                      type='password'
                      value={password}
                      onChange={(e) => setPassword(e.target.value)}
                    />
                    <div className={styles.newpassworderror}>
                      {passwordErr}
                    </div>
                    <div className={styles.newpasswordbuttonwrapper}>
                      <button
                        className={styles.newpasswordcontinuebutton}
                        aria-live='polite'
                        disabled={showResetMsg}
                      >
                        Continue
                      </button>
                    </div>
                  </form>
                </>
              )}
            </div>
          </div>
      </div>
  );
}

export default NewPassword;