import { TextField } from '@mui/material';

const NotesInput = ({
  inputLabel,
  label,
  type,
  name,
  onChange,
  onBlur,
  error,
  helperText,
}) => {
  return (
    <>
      <TextField
        fullWidth
        variant='outlined'
        // label={label}
        type={type}
        name={name}
        onBlur={onBlur}
        onChange={onChange}
        error={error}
        helperText={helperText}
        inputProps={{ maxLength: 100}}
        sx={{mt: 1, mb: 1, width: 550}}
        multiline
        rows={"2"}
      />
    </>    
  );
}

export default NotesInput;