import AppMain from './MainPages/AppMain/AppMain.js';

function App() {
  return (
    <div className='app'>
      <AppMain />
    </div>
  );
}

export default App;

