import { useState, useEffect } from 'react';
import { Box, Button, TextField, Typography } from '@mui/material';
import Select from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import InputLabel from '@mui/material/InputLabel';
import FormControl from '@mui/material/FormControl';
import dayjs from 'dayjs';
import { DatePicker } from '@mui/x-date-pickers';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Formik } from 'formik';
import * as yup from 'yup';
import useMediaQuery from '@mui/material/useMediaQuery';
import Header from '../../Components/Headers/Header';
import MaterialPopup from '../../Components/Popup/MaterialPopup';
import { supabase } from '../../Utilities/SupabaseClient';
import styles from './InventoryEntry.module.css';
import getFuelTypes from './Utilities/getFuelTypes';
import addInventoryToDB from './Utilities/addInventoryToDB';
import { useLocation } from 'react-router-dom';
import NotesInput from '../../Components/NotesInput/NotesInput';
import OneLineInput from '../../Components/OneLineInput/OneLineInput';
import TextAreaInput from '../../Components/TextAreaInput/TextAreaInput';

const checkoutSchema = yup.object().shape({
	fuelUsed: yup
		.number()
		.min(0, 'Must be zero or positive')
		.required('An answer is required'),
});

// Allows the user to add an item to emissions inventory.
const InventoryEntry = ({ title, subtitle, scope, explainer, tableName }) => {
	const isNonMobile = useMediaQuery('(min-width:600px)');
	const [isPopupOpen, setIsPopupOpen] = useState(false);
	const [session, setSession] = useState(null);
	const [fuel, setFuel] = useState('');
	const [units, setUnits] = useState('');
	const [fuelTypes, setFuelTypes] = useState([]);
	const [notes, setNotes] = useState('');
	const [startDate, setStartDate] = useState(dayjs());
	const [endDate, setEndDate] = useState(dayjs());
	const location = useLocation();
	const textToRender =
		scope === 1
			? 'Scope 1 emissions are from combustion at company operations you own or control.'
			: 'Scope 2 emissions are from purchased electricity, steam, or heat at company operations you own or control.';
	let scope2Label = 'Grid region';
	if (title === 'Steam & Heat') scope2Label = 'Steam and heat';
	let scope2AmountLabel = 'MWh';
	if (title === 'Steam & Heat') scope2AmountLabel = 'MMBTU';
	// let scope2Use = 'electricity';
	// if (title === 'Steam & Heat') scope2Use = 'steam or heat';
	let regionOrSteam = 'an eletricity grid region';
	if (title === 'Steam & Heat') regionOrSteam = 'steam and heat';

	const initialValues = {
		fuelUsed: 0,
	};

	useEffect(() => {
		supabase.auth.getSession().then(({ data: { session } }) => {
			setSession(session);
		});

		supabase.auth.onAuthStateChange((_event, session) => {
			setSession(session);
		});
	}, []);

	// Get the different fuel types from Airtable
	useEffect(() => {
		const callGetFuelTypes = async () => {
			const records = await getFuelTypes(tableName);
			setFuelTypes(records);
		};
		callGetFuelTypes();
	}, [location, tableName]);

	// Render the different fuel types as MenuItem components
	const renderMenuItems = () => {
		const renderedArray = fuelTypes.map((element, index) => {
			return (
				<MenuItem value={element['Name']} key={index.toString()}>
					{element['Name']} ({element['Units']})
				</MenuItem>
			);
		});

		return renderedArray;
	};

	// Deals with submission the form. Calls function which writes to the
	// DB.
	const handleFormSubmit = async (values, formik) => {
		try {
			if (session) {
				await addInventoryToDB(
					session.user.id,
					fuel,
					scope,
					values,
					units,
					startDate,
					endDate,
					tableName,
					notes
				);
			}
		} catch (error) {
			console.log(error); // Console logging error for now
		}
		setIsPopupOpen(true);
	};

	// Updates the combustion type in componenet state when the user
	// selects one.
	const handleSelectChange = (event) => {
		setFuel(event.target.value);
		if (fuelTypes.length > 0) {
			fuelTypes.forEach((item) => {
				if (item['Name'] === event.target.value) {
					setUnits(item['Units']);
				}
			});
		}
	};

	return (
		<>
			<Box m='20px'>
				<Header title={title} subtitle={subtitle} />
				<div className={styles.addsupplierformwrapper}>
					<Formik
						onSubmit={handleFormSubmit}
						initialValues={initialValues}
						validationSchema={checkoutSchema}
					>
						{({
							values,
							errors,
							touched,
							handleBlur,
							handleChange,
							handleSubmit,
						}) => (
							<form onSubmit={handleSubmit}>
								<Box
									// display='grid'
									display='flex'
									flexDirection='column'
									width='800px'
									gap='30px'
									// gridTemplateColumns='repeat(4, minmax(0, 1fr))'
									sx={{
										'& > div': {
											gridColumn: isNonMobile ? undefined : 'span 4',
										},
									}}
								>
									<Typography variant='h5' component='h2'>
										{textToRender} This page allows you to add emissions from{' '}
										{explainer} to your inventory. Choose{' '}
										{scope === 1 ? 'a fuel' : regionOrSteam} below.
									</Typography>

									<FormControl>
										<InputLabel id='combusiontypeselect'>
											{scope === 1 ? 'Combustion Type' : scope2Label}
										</InputLabel>
										<Select
											labelId='combusiontypeselect'
											id='combusiontypeselect'
											name='fuel'
											value={fuel || ''}
											label={scope === 1 ? 'Combustion Type' : scope2Label}
											onChange={handleSelectChange}
											color='info'
											sx={{ width: 300 }}
										>
											{renderMenuItems()}
										</Select>
									</FormControl>
									<Typography variant='h5' component='h2'>
										{scope === 1 && (
											<>
												Enter the amount of {fuel ? fuel : 'fuel'} used
												{units && ` (in ${units.toLowerCase()})`}:
											</>
										)}
										{scope === 2 && <>Entered the amount used:</>}
									</Typography>
									<TextField
										fullWidth
										variant='filled'
										type='number'
										label={
											scope === 1 ? 'Amount of fuel used' : scope2AmountLabel
										}
										onBlur={handleBlur}
										onChange={handleChange}
										value={values.fuelUsed || ''}
										name='fuelUsed'
										error={!!touched.fuelUsed && !!errors.fuelUsed}
										helperText={touched.fuelUsed && errors.fuelUsed}
										inputProps={{ step: 'any', lang: 'en-US' }}
										sx={{ width: 300 }}
									/>
									<Typography variant='h5' component='h2'>
										Enter the start date for this use of{' '}
										{scope === 1 ? 'fuel' : 'energy'}:
									</Typography>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											// defaultValue={dayjs('2023-01-01')}
											value={startDate || ''}
											name='startDate'
											onChange={setStartDate}
											sx={{ width: 300 }}
										/>
									</LocalizationProvider>
									<Typography variant='h5' component='h2'>
										Enter the end date for this use of{' '}
										{scope === 1 ? 'fuel' : 'energy'}:
									</Typography>
									<LocalizationProvider dateAdapter={AdapterDayjs}>
										<DatePicker
											// defaultValue={dayjs('2023-01-02')}
											value={endDate || ''}
											name='endDate'
											onChange={setEndDate}
											sx={{ width: 300 }}
										/>
									</LocalizationProvider>
									<Typography variant='h5' component='h2'>
										Notes for this inventory item:
									</Typography>
									<NotesInput
										id='notes'
										// inputLabel='Notes'
										name='notes'
										value={notes}
										onBlur={handleBlur}
										onChange={(e) => setNotes(e.target.value)}
										error={!!touched.notes && !!errors.notes}
										helperText={touched.notes && errors.notes}
									/>
									<Box display='flex' justifyContent='start' mt='20px'>
										<Button type='submit' color='secondary' variant='contained'>
											Add to GHG Inventory
										</Button>
									</Box>
								</Box>
								<MaterialPopup
									isOpen={isPopupOpen}
									setOpenFunc={setIsPopupOpen}
									title='Success'
									dialogContent='Added to inventory. The Emissions Inventory page shows your complete inventory.'
								/>
							</form>
						)}
					</Formik>
				</div>
			</Box>
		</>
	);
};

export default InventoryEntry;
