import { useTheme } from '@mui/material';
import { ResponsiveBar } from '@nivo/bar';
// import { tokens } from "../theme";
import { tokens } from '../../theme';

const BarChart = ({ isDashboard = false, inventory }) => {
  const theme = useTheme();
  const colors = tokens(theme.palette.mode);

  // Old hard coded chart data
  // const data = [
  //   {
  //     month: "2 Months Ago",
  //     emissions: 19.6,
  //   },
  //   {
  //     month: "Last Month",
  //     emissions: 29.9,
  //   },
  //   {
  //     month: "Current Month",
  //     emissions: 6.6,
  //   },
  // ]

  const data = inventory.map((item) => {
    return {
      name: item['name'],
      emissions: item['co2e'],
    }
  });

  const orderedData = data.toSorted((a, b) => {
    return (b['emissions'] - a['emissions']);
  });

  // Remove duplicate names from the data shown on the
  // chart.
  const namesEncountered = [];
  const sortedData = [];
  for (let i = 0; i < orderedData.length; i++) {
    if (namesEncountered.includes(orderedData[i]['name'])) continue;
    namesEncountered.push(orderedData[i]['name']);
    sortedData.push(orderedData[i]);
  }

  console.log(sortedData);
  return (
    <ResponsiveBar
      data={sortedData.slice(0, 5)}
      theme={{
        // added
        axis: {
          domain: {
            line: {
              stroke: colors.grey[100],
            },
          },
          legend: {
            text: {
              fill: colors.grey[100],
            },
          },
          ticks: {
            line: {
              stroke: colors.grey[100],
              strokeWidth: 1,
            },
            text: {
              fill: colors.grey[100],
            },
          },
        },
        legends: {
          text: {
            fill: colors.grey[100],
          },
        },
      }}
      keys={["emissions"]}
      indexBy="name"
      margin={{ top: 50, right: 130, bottom: 50, left: 60 }}
      padding={0.3}
      valueScale={{ type: "linear" }}
      indexScale={{ type: "band", round: true }}
      colors={{ scheme: "nivo" }}
      defs={[
        {
          id: "dots",
          type: "patternDots",
          background: "inherit",
          color: "#38bcb2",
          size: 4,
          padding: 1,
          stagger: true,
        },
        {
          id: "lines",
          type: "patternLines",
          background: "inherit",
          color: "#eed312",
          rotation: -45,
          lineWidth: 6,
          spacing: 10,
        },
      ]}
      borderColor={{
        from: "color",
        modifiers: [["darker", "1.6"]],
      }}
      axisTop={null}
      axisRight={null}
      axisBottom={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        // legend: 'One',
        // legend: isDashboard ? undefined : "Supplier", // changed
        legendPosition: "middle",
        legendOffset: 32,
      }}
      axisLeft={{
        tickSize: 5,
        tickPadding: 5,
        tickRotation: 0,
        legend: 'Metric Tons',
        // legend: isDashboard ? undefined : "Metric tons", // changed
        legendPosition: "middle",
        legendOffset: -40,
      }}
      enableLabel={false}
      labelSkipWidth={12}
      labelSkipHeight={12}
      labelTextColor={{
        from: "color",
        modifiers: [["darker", 1.6]],
      }}
      // legends={[
      //   {
      //     dataFrom: "keys",
      //     anchor: "bottom-right",
      //     direction: "column",
      //     justify: false,
      //     translateX: 120,
      //     translateY: 0,
      //     itemsSpacing: 2,
      //     itemWidth: 100,
      //     itemHeight: 20,
      //     itemDirection: "left-to-right",
      //     itemOpacity: 0.85,
      //     symbolSize: 20,
      //     effects: [
      //       {
      //         on: "hover",
      //         style: {
      //           itemOpacity: 1,
      //         },
      //       },
      //     ],
      //   },
      // ]}
      role="application"
      barAriaLabel={function (e) {
        return e.id + ": " + e.formattedValue + " in country: " + e.indexValue;
      }}
    />
  );
};

export default BarChart;
